<template>
  <VApp>
    <LayoutNotification></LayoutNotification>
    <DialogWrapper />

    <VMain>
      <VContainer fluid class="d-flex justify-center">
        <slot> </slot>
      </VContainer>
    </VMain>
  </VApp>
</template>

<script setup lang="ts">
import { DialogWrapper } from 'vue3-promise-dialog';


const publicConfig = useRuntimeConfig().public;
const faviconName = publicConfig.faviconName;

useFavicon(`/${faviconName}.png`, { rel: 'icon' });

</script>

<style lang="scss">
@import '@fontsource/open-sans/index.css';
</style>
